<template>
    <div class="Head">
        <div class="Back" @click="back">
            <img src="../../assets/SpecialScenic/new/back.png" style="width:100%;height:100%">
        </div>
    </div>
    <div class="XQBox">
        <div class="LBox">
            <!-- 图片区域 -->
            <div class="ImgBox swiper">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" v-for="(item,index) in imgList" :key="index">
                        <img :src="item.resourceUrl" style="width: 100%;height: 100%;">
                    </div>
                </div>

            </div>
            <!-- 视频区域 -->
            <div class="videoBox" v-if="videoList.length>0">
                <div class="Title">
                    <span class="left"></span>
                    <span>景点视频</span>
                    <span class="right"></span>
                </div>
                <div class="VBox">
                    <video :src="current.resourceUrl" ref="myVideo" @play="HandleFullScreenOne" @ended="HandleExitFullScreenOne" controls="controls" style="width:100%;height: 100%;border-radius: 1rem;"></video>
                </div>
                <div class="VInfo">
                    <div class="Title">{{ current.title }}</div>
                    <div class="summary">{{ current.summary }}</div>
                </div>
                <div class="VList">
                    <div class="VItem" v-for="(item,index) in videoList" :key="index" @click="changeVideo(item)">
                        <video :src="item.resourceUrl" style="width:100%;height: 100%;border-radius: 0.8rem;"></video>
                    </div>
                </div>
                <div class="noMore">没有更多视频了...</div>
            </div>
        </div>
        <div class="RBox">
            <div class="Title">{{  conData.title  }}</div>
            <div class="Content" v-html="conData.content"> </div>
        </div>
    </div>

</template>
<script>
import $ from 'jquery'
export default {
    data () {
        return {
            conData: {},
            imgList: [],
            videoList: [],
            current: {}
        }
    },
    mounted () {
        document.addEventListener('fullscreenchange', this.handleFullscreenChange);
        document.addEventListener('mozfullscreenchange', this.handleFullscreenChange);
        document.addEventListener('webkitfullscreenchange', this.handleFullscreenChange);
        this.getDetail()

    },
    methods: {
        // 获取详情
        getDetail () {
            let t = this;
            var id = t.$route.query.id
            var axios = require('axios');
            var config = {
                method: 'get',
                url: this.$Schttp + '/vtp/app/scenicSpot/detail/' + id,
            };
            axios(config)
                .then(function (res) {
                    console.log(res);
                    t.conData = res.data.data
                    t.imgList = t.conData.imgs
                    t.videoList = t.conData.videos
                    if (t.videoList.length > 0) t.current = t.videoList[0]
                    t.initSwiper()
                })
                .catch(function (error) {
                });
        },
        changeVideo (data) {
            this.current = data
        },
        initSwiper () {
            let mySwiper = new Swiper('.swiper', {
                // mousewheel: true,
                direction: 'vertical',
                speed: 1000,
                loop: true,
                autoplay: {
                    delay: 3000,
                },
                on: {
                    slideChangeTransitionStart: function () {
                        let idx = this.activeIndex - 1
                        $('.ImgTitle').css('color', 'rgba(255,255,255,0.6)')

                    },
                    slideChangeTransitionEnd: function () {
                        let idx = this.activeIndex
                        $('.ImgTitle').css('color', 'rgba(255,255,255,1)')
                    },
                    init: function () {
                        let swiperSlide = $('.ImgBox .swiper-wrapper .swiper-slide')
                        $(swiperSlide[0].children[0]).addClass('addAnimationImg')
                        $(swiperSlide[0].children[0]).on('animationend', function () {
                            $(swiperSlide[0].children[0]).removeClass('addAnimationImg')
                        })

                    },
                    slideChange: function () {
                        let activeIndex = this.activeIndex
                        let swiperSlide = $('.ImgBox .swiper-wrapper .swiper-slide')
                        $(swiperSlide[activeIndex].children[0]).addClass('addAnimationImg')
                        $(swiperSlide[activeIndex].children[0]).on('animationend', function () {
                            $(swiperSlide[activeIndex].children[0]).removeClass('addAnimationImg')
                        })

                    },

                },
            })
        },
        // 退出全屏暂停播放
        handleFullscreenChange () {
            const video = this.$refs.myVideo;
            if (!document.fullscreenElement && !document.mozFullScreenElement && !document.webkitFullscreenElement) {
                video.pause(); // 当视频退出全屏时暂停播放
            }
        },
        //点击视频全屏播放
        HandleFullScreenOne (e) {
            this.launchFullscreen(e.target);
        },
        // 进入全屏
        launchFullscreen (element) {
            //此方法不可以在異步任務中執行，否則火狐無法全屏
            if (element.requestFullscreen) {
                element.requestFullscreen();
            } else if (element.mozRequestFullScreen) {
                element.mozRequestFullScreen();
            } else if (element.msRequestFullscreen) {
                element.msRequestFullscreen();
            } else if (element.oRequestFullscreen) {
                element.oRequestFullscreen();
            } else if (element.webkitRequestFullscreen) {
                element.webkitRequestFullScreen();
            } else {
                var docHtml = document.documentElement;
                var docBody = document.body;
                var videobox = element;
                var cssText = "width:100%;height:100%;overflow:hidden;";
                docHtml.style.cssText = cssText;
                docBody.style.cssText = cssText;
                videobox.style.cssText = cssText + ";" + "margin:0px;padding:0px;";
                document.IsFullScreen = true;
            }
        },
        HandleExitFullScreenOne (e) {
            this.exitFullscreen(e.target);
        },
        exitFullscreen (element) {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.oRequestFullscreen) {
                document.oCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            } else {
                var docHtml = document.documentElement;
                var docBody = document.body;
                var videobox = element;
                docHtml.style.cssText = "";
                docBody.style.cssText = "";
                videobox.style.cssText = "";
                document.IsFullScreen = false;
            }
        },
        back () {
            window.history.go(-1);
        }
    },
    beforeUnmount () {
        // 销毁监听视频
        document.removeEventListener('fullscreenchange', this.handleFullscreenChange);
        document.removeEventListener('mozfullscreenchange', this.handleFullscreenChange);
        document.removeEventListener('webkitfullscreenchange', this.handleFullscreenChange);
    }
}
</script>
<style lang="less" scoped>
@import '../../assets/unify.less';
.Head {
    width: 100%;
    height: 10vh;
    background: url('../../assets/SpecialScenic/detail/headBg.jpg') no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    padding: 0 10%;
    display: flex;
    // justify-content: space-between;
    align-items: center;
    font-size: @First_Title_Size;
    font-weight: 600;
    .Back {
        // position: absolute;
        width: 7.5vh;
        height: 7vh;
        // left: 20%;
        // top: 3vh;
        z-index: 999;
        cursor: pointer;
    }
}
.XQBox {
    width: 100%;
    display: flex;
    .Title {
        font-size: @First_Title_Size;
        font-weight: 600;
    }
    .LBox {
        width: 70%;
        position: relative;

        .ImgBox {
            width: 100%;
            height: 90vh;
            .swiper-wrapper {
                .swiper-slide {
                    width: 100%;
                    background: #000;
                    position: relative;
                    .ImgTitle {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        left: 0;
                        top: 0;
                        color: #fff;
                        font-size: 8vh;
                        font-weight: 500;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }
            .addAnimationImg {
                animation: imgScale 3s 1 forwards;
            }

            @keyframes imgScale {
                0% {
                    transform: scale(1.5);
                }

                100% {
                    transform: scale(1);
                }
            }
        }
        .videoBox {
            width: 100%;
            min-height: 100vh;
            background: url(../../assets/SpecialScenic/new/XQbg.jpg) no-repeat;
            background-size: 100% 100%;
            .Title {
                padding: 8vh 0 6vh;
                // text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;

                span {
                    position: relative;
                    display: inline-block;
                    padding: 0 2rem;
                }
                .left {
                    width: 3rem;
                    height: 0.5vh;
                    background: #000;
                }
                .left:after {
                    content: ' ';
                    width: 1vh;
                    height: 1vh;
                    background: #000;
                    border-radius: 50%;
                    position: absolute;
                    left: 0;
                    top: -50%;
                }
                .right {
                    width: 3rem;
                    height: 0.5vh;
                    background: #000;
                }
                .right:after {
                    content: ' ';
                    width: 1vh;
                    height: 1vh;
                    background: #000;
                    border-radius: 50%;
                    position: absolute;
                    right: 0;
                    top: -50%;
                }
            }

            .VBox {
                height: 60vh;
                width: 80%;
                background: #000;
                margin-left: 12%;
                border-radius: 1rem;
                box-shadow: 0px 0px 2rem 0px rgba(0, 0, 0, 0.5);
            }
            .VInfo {
                width: 80%;
                margin-left: 12%;
                font-size: @Content_Text_Size;
                line-height: @Content_Text_LineHeight;
                .Title {
                    text-align: left;
                    font-size: @Second_Title_Size;
                    padding-bottom: 2vh;
                }
            }
            .VList {
                width: 80%;
                margin-left: 12%;
                margin: 3vh 0 0 12%;
                display: flex;
                flex-wrap: wrap;
                padding-bottom: 3vh;
                .VItem {
                    width: 23%;
                    margin-right: 2%;
                    cursor: pointer;
                    border-radius: 1rem;
                }
            }
            .noMore {
                width: 80%;
                margin-left: 12%;
                font-size: @Content_Text_Size;
                padding-bottom: 2vh;
            }
        }
    }
    .RBox {
        margin-top: 8vh;
        width: 30%;
        box-sizing: border-box;
        padding: 1rem 4rem 1rem 2rem;
        .Title {
            position: relative;
            margin-bottom: 5vh;
        }
        .Title:after {
            content: ' ';
            width: 100px;
            height: 0.8vh;
            background: #000;
            position: absolute;
            bottom: -0.5rem;
            left: 0;
        }
        .Content {
            width: 100%;
            font-size: @Content_Text_Size;
            /deep/ img {
                width: 100%;
            }
        }
    }
}
</style>